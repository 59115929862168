import React from "react";
import Navbar from "../homepage/NavBar";
import MoreAboutUs from "./MoreAboutUs";
import Footer from "../homepage/Footer";

const AboutUsPage = () => {

    return (
    <html lang="ar" dir="rtl">
<Navbar />
<MoreAboutUs/>
<Footer/>
        </html>
);

};

export default AboutUsPage